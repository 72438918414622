import { NgModule } from '@angular/core';
import { FaceComponent } from './face.component';

@NgModule({
  declarations: [FaceComponent],
  exports: [FaceComponent],
  imports: [
  ]
})
export class FaceModule { }
