import { Component, Input } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-point-of-interest-details',
  templateUrl: './point-of-interest-details.component.html',
  styleUrls: ['./point-of-interest-details.component.scss']
})
export class PointOfInterestDetailsComponent {

  @Input() wikiImage = '';
  @Input() headline = '';
  @Input() infoText = '';
  @Input() coordinates: any = [];
  @Input() extmetadata: any = {};
  @Input() wikiLanguage = '';

  getOS() {
    let OSName = 'Unknown OS';
    if (navigator.appVersion.indexOf('Win') !== -1) { OSName = 'Windows'; }
    if (navigator.appVersion.indexOf('Mac') !== -1) { OSName = 'MacOS'; }
    if (navigator.appVersion.indexOf('OS') !== -1) { OSName = 'OS'; }
    if (navigator.appVersion.indexOf('X11') !== -1) { OSName = 'UNIX'; }
    if (navigator.appVersion.indexOf('Linux') !== -1) { OSName = 'Linux'; }
    return OSName;
  }
  getOpenMapsLink() {
    this.loggerInfo('coordinates', this.coordinates);
    if (this.getOS() === 'MacOS' || this.getOS() === 'OS') {
      window.location.href = 'http://maps.apple.com/?q=' + this.coordinates[0] + ',' + this.coordinates[1];
    } else {
      window.location.href = 'https://maps.google.com/maps?daddr=' + this.coordinates[0] + ',' + this.coordinates[1] + '&amp;ll=';
    }
  }
  loggerInfo(...log) {
    if (window.top.location.href.includes('debugMode=true')){
      console.log('logging: ', ...log);
    }
  }
}
