import { PointOfInterestListComponent } from 'src/components/point-of-interest-list/point-of-interest-list.component';
import { NgModule, } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { IntroComponent } from 'src/components/intro/intro.component';


const routes: Routes = [{
  path: 'app/exploring',
  component: PointOfInterestListComponent
},
{
  path: 'exploring',
  component: PointOfInterestListComponent
},
{
  path: '',
  component: IntroComponent
},
{
  path: 'dev',
  component: IntroComponent
},
{
  path: 'app',
  component: IntroComponent
}

// { path: 'all', component: PointOfInterestListComponent },
// { path: 'article', component: PointOfInterestDetailsComponent },
// { path: 'article/:keyword', component: PointOfInterestDetailsComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
