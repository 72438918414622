<div id="face">
    <div class="face-bg">
      <img  width="150" height="200" src="./assets/face/a.jpeg">

    <img  width="150" height="200" *ngIf="face === 'a'" src="./assets/face/a.jpeg">
    <img  width="150" height="200" *ngIf="face === 'b'" src="./assets/face/b.jpeg">
    <img  width="150" height="200" *ngIf="face === 'c'" src="./assets/face/c.jpeg">
    <img  width="150" height="200" *ngIf="face === 'd'" src="./assets/face/d.jpeg">
    <img  width="150" height="200" *ngIf="face === 'f'" src="./assets/face/e.jpeg">
    <img  width="150" height="200" *ngIf="face === 'g'" src="./assets/face/g.jpeg">
    <img  width="150" height="200" *ngIf="face === 'h'" src="./assets/face/h.jpeg">
    <img  width="150" height="200" *ngIf="face === 'i'" src="./assets/face/i.jpeg">
    <img  width="150" height="200" *ngIf="face === 'j'" src="./assets/face/j.jpeg">
    <img  width="150" height="200" *ngIf="face === 'k'" src="./assets/face/k.jpeg">
    <img  width="150" height="200" *ngIf="face === 'l'" src="./assets/face/l.jpeg">
    <img  width="150" height="200" *ngIf="face === 'm'" src="./assets/face/m.jpeg">
    <img  width="150" height="200" *ngIf="face === 'n'" src="./assets/face/n.jpeg">
    <img  width="150" height="200" *ngIf="face === 'o'" src="./assets/face/o.jpeg">
    <img  width="150" height="200" *ngIf="face === 'p'" src="./assets/face/p.jpeg">
    <img  width="150" height="200" *ngIf="face === 'q'" src="./assets/face/q.jpeg">
    <img  width="150" height="200" *ngIf="face === 'r'" src="./assets/face/r.jpeg">
    <img  width="150" height="200" *ngIf="face === 's'" src="./assets/face/s.jpeg">
    <img  width="150" height="200" *ngIf="face === 't'" src="./assets/face/t.jpeg">
    <img  width="150" height="200" *ngIf="face === 'u'" src="./assets/face/u.jpeg">
    <img  width="150" height="200" *ngIf="face === 'v'" src="./assets/face/v.jpeg">
    <img  width="150" height="200" *ngIf="face === 'w'" src="./assets/face/w.jpeg">
    <img  width="150" height="200" *ngIf="face === 'x'" src="./assets/face/x.jpeg">
    <img  width="150" height="200" *ngIf="face === 'y'" src="./assets/face/y.jpeg">
    <img  width="150" height="200" *ngIf="face === 'z'" src="./assets/face/z.jpeg">
    <img  width="150" height="200" *ngIf="face === ' '" src="./assets/face/default.jpeg">
  </div>
