<!--div id="face">
  <div class="face-bg">

    <img
      width="150"
      height="200"
      *ngIf="face === 'a'"
      src="./assets/face/a.jpeg"
    />
    <img
      width="150"
      height="200"
      *ngIf="face === 'b'"
      src="./assets/face/b.jpeg"
    />
    <img
      width="150"
      height="200"
      *ngIf="face === 'c'"
      src="./assets/face/c.jpeg"
    />
    <img
      width="150"
      height="200"
      *ngIf="face === 'd'"
      src="./assets/face/d.jpeg"
    />
    <img
      width="150"
      height="200"
      *ngIf="face === 'f'"
      src="./assets/face/e.jpeg"
    />
    <img
      width="150"
      height="200"
      *ngIf="face === 'g'"
      src="./assets/face/g.jpeg"
    />
    <img
      width="150"
      height="200"
      *ngIf="face === 'h'"
      src="./assets/face/h.jpeg"
    />
    <img
      width="150"
      height="200"
      *ngIf="face === 'i'"
      src="./assets/face/i.jpeg"
    />
    <img
      width="150"
      height="200"
      *ngIf="face === 'j'"
      src="./assets/face/j.jpeg"
    />
    <img
      width="150"
      height="200"
      *ngIf="face === 'k'"
      src="./assets/face/k.jpeg"
    />
    <img
      width="150"
      height="200"
      *ngIf="face === 'l'"
      src="./assets/face/l.jpeg"
    />
    <img
      width="150"
      height="200"
      *ngIf="face === 'm'"
      src="./assets/face/m.jpeg"
    />
    <img
      width="150"
      height="200"
      *ngIf="face === 'n'"
      src="./assets/face/n.jpeg"
    />
    <img
      width="150"
      height="200"
      *ngIf="face === 'o'"
      src="./assets/face/o.jpeg"
    />
    <img
      width="150"
      height="200"
      *ngIf="face === 'p'"
      src="./assets/face/p.jpeg"
    />
    <img
      width="150"
      height="200"
      *ngIf="face === 'q'"
      src="./assets/face/q.jpeg"
    />
    <img
      width="150"
      height="200"
      *ngIf="face === 'r'"
      src="./assets/face/r.jpeg"
    />
    <img
      width="150"
      height="200"
      *ngIf="face === 's'"
      src="./assets/face/s.jpeg"
    />
    <img
      width="150"
      height="200"
      *ngIf="face === 't'"
      src="./assets/face/t.jpeg"
    />
    <img
      width="150"
      height="200"
      *ngIf="face === 'u'"
      src="./assets/face/u.jpeg"
    />
    <img
      width="150"
      height="200"
      *ngIf="face === 'v'"
      src="./assets/face/v.jpeg"
    />
    <img
      width="150"
      height="200"
      *ngIf="face === 'w'"
      src="./assets/face/w.jpeg"
    />
    <img
      width="150"
      height="200"
      *ngIf="face === 'x'"
      src="./assets/face/x.jpeg"
    />
    <img
      width="150"
      height="200"
      *ngIf="face === 'y'"
      src="./assets/face/y.jpeg"
    />
    <img
      width="150"
      height="200"
      *ngIf="face === 'z'"
      src="./assets/face/z.jpeg"
    />
    <img
      width="150"
      height="200"
      *ngIf="face === ' '"
      src="./assets/face/default.jpeg"
    />
  </div>
</-div-->

<section class="start">


  <div class="center">
    <h1>
      <span class="width-button">&nbsp; SIGHT GUIDE &nbsp;</span>
    </h1>
    <!-- <h1>SightGuide</h1> -->
    <!-- <div class="icon-loader" (click)="speak('SightGuide !')" > -->
    <div
      class="icon-loader"
      (click)="speak('SightGuide !')"
      [routerLink]="'exploring'"
    >
  </div>
     <!-- <img
      *ngIf="!isInit"
      src="../../assets/ic_launcher.png"
      (click)="speak('Exploring now'); isInit = true"
    /> -->
    <!-- <iframe onload="waitForVideo()"
    (click)="waitForVideo()"
    *ngIf="!init"
      class="video"
      src="https://www.youtube-nocookie.com/embed/d-T3Su8SKuE" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen autoplay autoplay="true">
    </iframe> -->
    <!-- <video
      class="video"
      controls
      autoplay="true"
      autoplay
      height="auto"
      (click)="waitForVideo()"
      *ngIf="!init"
    >
      <source    class="video"
        (click)="waitForVideo()"
        *ngIf="!init"
        src="https://www.youtube-nocookie.com/embed/d-T3Su8SKuE"
      />
      I'm sorry; your browser doesn't support HTML5 video with MP4 with H.264.
    </video> -->
    <!-- <br />
    <br />
    <button class="init" *ngIf="!isInit" (click)="speak(''); isInit = true">
      <h3>Click here,</h3>
      <h3>to explore your world</h3>
    </button> -->
    <br />
    <button
      class="width-button"
      (click)="speak('SightGuide !')"
      [routerLink]="'exploring'"
    >
      START
    </button>
    <div id="imprint">... like {{ counter }} people did before you</div>
    <br />
    <!-- <button class="width-button" (click)="downloadFile('www.SightGuide.app', 'www.SightGuide.app')">
      SaveToScreen
    </button> <br /> -->
    <!-- <a href="../../assets/SightGuide.webloc" download="SightGuide.html"><button class="width-button">Save-link</button></a> -->

<span>
    <div id="imprint bottom">
      <a
        class="default"
        target="_blank"
        href="https://www.sightguide.app/Impressum-SightGuide.pdf"
        >&nbsp;Impressum&nbsp;</a
      >
    </div>
    <div id="imprint bottom">
      <a
        class="default"
        target="_blank"
        href="https://www.sightguide.app/Nutzungsbedingungen-SightGuide.pdf"
        >&nbsp;Nutzungsbedingungen&nbsp;</a
      >
    </div>
    <div id="imprint bottom">
      <a
        class="default"
        target="_blank"
        href="https://www.sightguide.app/Datenschutz-SightGuide.pdf"
        >&nbsp;Datenschutz&nbsp;</a
      >
    </div>
</span>
    <br />
    <div id="donate-button-container">
      <a
        href="https://www.paypal.com/donate/?hosted_button_id=VJRVC4Y4Y9QYL"
        target="_self"
      >
        <div id="donate-button">
          <img src="./assets/btn_donate_SM.gif" />
        </div>
      </a>
    </div>
  </div>
</section>
