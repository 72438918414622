import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'SIGHTGUIDE';
  value = '';
  init = false;
  hidden: boolean;
  isMobileApp: boolean = false;
  isPayedApp: boolean = false;
  debugMode: boolean = false;

  loggerInfo(...log) {
    if (this.debugMode) {
      console.log('logging: ', ...log);
    }
  }

  ngOnInit() {
    this.debugMode = window.top.location.href.includes('debugMode=true');
    this.loggerInfo('cookie', window.document.cookie);
    this.loggerInfo('cookie', document.cookie);

    // window.document.body.getElementsByTagName('input')[0].focus();
    if (window.top.location.href.includes('i-phone-payed-app-webview-version=yes')
    || window.top.location.pathname.includes('i-phone-payed-app-webview-version=yes')) {
      this.isMobileApp = true;
    } else {
      this.isMobileApp = false;

    }

  }
  // run(value) {
  //   this.loggerInfo(value);
  //   this.init = value === 'start';
  //   this.hidden = this.init === true ? true : false;
  // }

  setCookie(cname, cvalue, exdays) {
    this.loggerInfo(cname, cvalue, exdays);
    const d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    const expires = 'expires=' + d.toUTCString();
    document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
  }

  // isMobileDevice() {
  //   window.innerWidth < window.innerHeight;
  // }

  getCookie(cname) {
    const name = cname + '=';
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      this.loggerInfo(cname, ca, c);

      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return '';
  }

  checkCookie(name: string) {
    const user = this.getCookie(name);
    this.loggerInfo(user);
    if (user !== '') {
      alert('Welcome again ' + user);
    } else {
      // user = prompt('Please enter your name:', '');
      if (user !== '' && user != null) {
      }
      this.setCookie(name, user, 365);
    }
  }
}

