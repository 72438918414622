import { Component, Input, OnInit, ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'app-face',
  templateUrl: './face.component.html',
  styleUrls: ['./face.component.css']
})
export class FaceComponent implements OnInit {

  text = "Wilkommen bei SightGuide app tourist compass";
  textarray: Array<String> = new Array<String>();
  index = 0;
  face: String;

  constructor(private cdr: ChangeDetectorRef) {
  }

  TTS: SpeechSynthesisUtterance = new SpeechSynthesisUtterance();
  intervall;

  start() {
    this.face = '';
    this.getTextSigns();
    let i = 0;
    this.intervall = setInterval(() => {
      i++;
      // if (i > this.textarray.length) {
      // this.deleteIntervall();
      // }
      this.face = this.textarray[i];
      console.log( this.textarray);
      console.log( this.face);
    }, 330);
  }

  deleteIntervall() {
    this.intervall = null;
  }

  // ngOnInit(): void {
  //     this.start();
  // }
  getTextSigns() {
    for (let index = 0; index < this.text.length; index++) {
      this.textarray[index] = this.text.substring(index, index + 1);
    }
  }


  speak(textString: string) {

    this.TTS.text  = '';
    if (speechSynthesis) {
      const voices = speechSynthesis.getVoices();
      voices.forEach((value, index) => {
        if (value.toString() === 'de-DE') {
          this.TTS.voice = voices[index];
          return;
        }
      });
      this.TTS.text = textString;
      speechSynthesis.speak(this.TTS);
    }
  }



  ngOnInit() {
    this.face = '';
    this.getTextSigns();
    let i = 0;
    this.intervall = setInterval(() => {
      i++;
      // if(i>this.textarray.length) {
      // this.deleteIntervall();
      // }
      this.face = this.textarray[i];
      this.cdr.markForCheck();
      console.log( this.textarray);
      console.log( this.face);
      this.cdr.detectChanges();

    }, 330);

    this.speak(this.text);
  }


}
