import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ApiRequestsService {
  apiKey = '';
  result: any;
  q: any;
  keyword: string;
  dist = 10000;
  imgSize = 300;
  constructor(private https: HttpClient) { }

  getCoordinatesResult(keyword, locale, isFeatured = false) {
    const params = '&prop=extracts&exintro=&explaintext=&titles=' + keyword + '&prop=coordinates';
    return this.call(locale, params, isFeatured);
    /*
        return this.https.get('https://'
          + locale +
          '.wikipedia.org/w/api.php?format=json&origin=*&action=query&prop=extracts&exintro=&explaintext=&titles='
          + keyword + '&prop=coordinates');
    */
  }
  getTextIntroResult(keyword, locale, isFeatured = false) {
    const params = '&prop=extracts&exintro=&explaintext=&titles=' + keyword;
    return this.call(locale, params, isFeatured);

    /* return this.https.get('https://'
       + locale +
       '.wikipedia.org/w/api.php?formatf=json&origin=*&action=query&prop=extracts&exintro=&explaintext=&titles='
       + keyword);
       */
  }

  getListResult(lat, lng, locale, isFeatured = false) {
    const geoLocationRequest =
      '&generator=geosearch&prop=coordinates%7Ccategories%7Cpageimages&piprop=thumbnail%7Cname&pithumbsize=' +
      this.imgSize + '&ggsradius=' + this.dist + '&ggscoord=' +
      lat + '%7C'
      + lng
      + '&cllimit=500&colimit=500&ggsprop=dim&ggslimit=100';

    const imagerequest =
      '&generator=geosearch&prop=coordinates%7Cpageimages&piprop=thumbnail%7Cname&pithumbsize=' +
      this.imgSize + '&ggsradius=' + this.dist + '&ggscoord=' +
      lat + '%7C'
      + lng +
      '&ggsprop=dim';
    return this.call(locale, geoLocationRequest, isFeatured);
    /*    return this.https.get('https://'
          + locale +
          '.wikipedia.org/w/api.php?format=json&origin=*&action=query' + geoLocationRequest);
    */
  }

  getMetadataByImageNameUrl(pageimage, locale, isFeatured = false) {
    const params = '&titles=Image:' + pageimage + '&prop=imageinfo&iiprop=extmetadata';
    return this.call(locale, params, isFeatured);
    /*return this.https.get('https://'
      + locale +
      '.wikipedia.org/w/api.php?action=query&origin=*&format=json&titles=Image:' + pageimage + '&prop=imageinfo&iiprop=extmetadata');
      */
  }

  translate(locale, textIntro, isFeatured = false) {
    locale = locale.split('-')[0];
    // let params = new HttpParams();
    // params = params.append('q', textIntro);
    // params = params.append('target', locale);
    // params = params.append('key ', this.apiKey);
    // out of scope
    // return this.https.get('https://translate.google.com/translate_tts?ie=UTF-8&tl='
    //   + locale + '&tk=52269.458629&q='
    //   + textIntro + '&client=tw-ob');

    // return this.https.get('https://translate.google.com/translate_tts?ie=UTF-8&tl='
    // + locale + '&client=tw-ob&q=' + textIntro + '&rd=' + Math.random() * Math.random());
    //   // 'https://translation.googleapis.com/language/translate';
  }

  call(locale, params, isFeatured) {
    const wikiApiUrl = 'https://' + locale + '.wikipedia.org/w/api.php?format=json&origin=*&action=query' + params;
    const localApi = 'https://SightGuide.app/api/index.php?params=true' + params + '&wikilocale=' + locale;
    const relativeLocalApi = '../../../api/index.php?params=true' + params + '&wikilocale=' + locale;
    // const url = isFeatured === true ? localApi : wikiApiUrl;
    // const url = localApi; // dev
    const url = wikiApiUrl; // dev

    return this.https.get(url);
  }

  getFanCalls() {
    const apiUrl = 'https://SightGuide.app/api/app-calls.php';
    return this.https.get(apiUrl);
  }
}
